<template>
  <!-- side popup modal -->
  <form
    method="post"
    @submit.prevent="submitHandler()"
    enctype="multipart/form-data"
  >
    <div class="form-field-group">
      <h3 class="form-title">
        <template v-if="category.Id">Edit</template>
        <template v-else>Add</template>
        <template v-if="type === 'sub-category'"> Sub</template> Category
      </h3>
      <div class="row grid-1">
        <div class="col form-group d-flex align-items-center">
          <label class="form-label" for="category-name">Name</label>
          <div class="form-field">
            <input
              class="form-control"
              id="category-name"
              placeholder="Name"
              type="text"
              v-model.trim="category.name"
            />
            <span
              v-if="submitted && v$.category.name.required.$invalid"
              class="error"
              >* required.</span
            >
            <span v-if="v$.category.name.maxLength.$invalid" class="error"
              >Category Name should have at most
              {{ v$.category.name.maxLength.$params.max }}.</span
            >
          </div>
        </div>
      </div>
      <div class="row grid-1" v-if="type === 'sub-category'">
        <div class="col form-group d-flex align-items-center">
          <label class="form-label" for="parent-category-name">Parent</label>
          <div class="form-field">
            <select
              class="form-control select-box"
              v-model="category.parentId"
              :disabled="category.length > 0"
            >
              <option value="">Select parent category</option>
              <template v-for="(c, i) in categories" :key="i">
                <option :value="c.id">{{ c.text }}</option>
              </template>
            </select>
            <span
              v-if="submitted && v$.category.parentId.required.$invalid"
              class="error"
              >* required.</span
            >
          </div>
        </div>
      </div>
    </div>

    <div class="form-controls">
      <div class="button-row d-flex justify-content-end">
        <button
          class="ml-auto secondary-btn"
          title="Cancel"
          type="button"
          @click.prevent="$emit('close')"
        >
          CANCEL
        </button>
        <button
          class="ml-3 js-btn-next primary-btn"
          type="submit"
          @click.prevent="submitHandler()"
        >
          <template v-if="category.Id">Update</template>
          <template v-else>Create</template>
        </button>
      </div>
    </div>
  </form>
</template>
<script>
import '@vuepic/vue-datepicker/dist/main.css'
import useVuelidate from "@vuelidate/core";
import { required, maxLength, requiredIf } from "@vuelidate/validators";
import common from "../mixins/common";
import profilePrefix from "../services/profilePrefix.service";

export default {
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  props: {
    editCategory: {
      type: Object,
      required: false,
    },
    categories: {
      type: Array,
      required: false,
      default: () => [],
    },
    type: {
      type: String,
      default: () => {
        "category";
      },
      validator: function (value) {
        // The value must match one of these strings
        return ["category", "sub-category"].indexOf(value) !== -1;
      },
    },
  },
  mixins: [common],
  data: () => ({
    submitted: false,
    category: {
      Id: "",
      name: "",
      type: "",
      parentId: "",
    },
  }),
  validations() {
    return {
      category: {
        name: {
          required,
          maxLength: maxLength(150),
        },
        parentId: {
          required: requiredIf(function () {
            if (this.type === "sub-category") {
              return true;
            }

            return false;
          }),
        },
      },
    };
  },
  created() {
    const vm = this;
    if (
      vm.editCategory &&
      "Id" in vm.editCategory &&
      "name" in vm.editCategory
    ) {
      vm.category = vm.editCategory;
    }
  },
  methods: {
    async submitHandler() {
      let vm = this;

      vm.submitted = true;
      vm.v$.$touch();

      const isValidate = await vm.v$.$validate();

      if (!isValidate) return;

      vm.category.type = vm.type;

      let formData = vm.category;

      if (vm.category.Id) {
        try {
          vm.$store.commit("loadingStatus", true);
          const response = await profilePrefix.update(formData, vm.category.Id);
          vm.toastMessage(response.message, "success");
          vm.$emit("refreshList");
          vm.$emit("close");
          vm.$store.commit("loadingStatus", false);
        } catch (error) {
          const message = vm.errorMessage(error);
          vm.toastMessage(message, "error");
          vm.$store.commit("loadingStatus", false);
        }
      } else {
        try {
          delete formData.Id;
          vm.$store.commit("loadingStatus", true);
          const response = await profilePrefix.create(formData);
          vm.toastMessage(response.message, "success");
          vm.$emit("refreshList");
          vm.$emit("close");
          vm.$store.commit("loadingStatus", false);
        } catch (error) {
          const message = vm.errorMessage(error);
          vm.toastMessage(message, "error");
          vm.$store.commit("loadingStatus", false);
        }
      }
    },
  },
};
</script>
